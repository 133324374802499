* {
    box-sizing: border-box;
  }
  
  html,
  body,
  #root {
    width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  body {
    background-color: #000000;
    color: rgb(0, 0, 0);
    user-select: none;
  }
  